import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import NavBarTop from './NavBarTop'
import { useStore } from '../../store'
import styled from '@emotion/styled'
import { useEffect } from 'react'

const ExplorerLayoutContainer = styled(Container)`
  :focus {
    outline: none;
    border: none;
  }
`

export default function ExplorerLayout({ children }) {
  const { setShowLeanBackMode } = useStore()

  useEffect(() => {
    document.getElementById('container').focus()
  }, [])

  return (
    <>
      <ExplorerLayoutContainer
        tabIndex={0}
        id="container"
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            setShowLeanBackMode(false)
          }
        }}
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          padding: '0 !important',
          maxWidth: '1920',
        }}
      >
        <Box
          sx={{
            height: '100%',
            padding: ['0px 20px', '0px 40px', '0px 40px'],
          }}
        >
          <NavBarTop />
          {children}
          <Box
            sx={{
              width: '100%',
              height: '100px',
              bottom: 0,
            }}
          />
        </Box>
      </ExplorerLayoutContainer>
    </>
  )
}
